import request from "./request";
// 获取文件和文件夹
export function getFolderAndFile(data) {
	return request({
		url: "kanbox/pageLimitDatas",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 新建文件夹
export function createFileAndFolder(data) {
	return request({
		url: "kanbox/createfile",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 编辑文件夹
export function editFolder(data) {
	return request({
		url: "kanbox/editFiled",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 上传文件
export function uploadFile(data) {
	return request({
		url: "kanbox/upload",
		method: "post",
		data,
	});
}

// 删除文件夹或文件
export function deleteFileOrFolder(data) {
	return request({
		url: "kanbox/fileDel",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}
