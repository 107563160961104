<template>
  <div class="memo-page view-page">
    <!-- 搜素 -->
    <c-search searchTitle="模糊查询" valueFormat="yyyyMMdd" placeholder="关键字" isBorder :isDate="true" @search="searchFileName"></c-search>
    <!-- 分类 -->
    <c-navigation v-show="viewFolderPath.length == 1" @change="changeNavigation" title="网盘性质" :list="cloudTypeList" :isBorder="true"></c-navigation>
    <!-- 按钮 -->
    <c-operate v-if="isBtn">
      <template #right>
        <div class="btn-group">
          <!-- <div class="btn-list export" @click="getDeceptionDeskFile">前台文件</div> -->
          <div class="btn-list courtDate" @click="showCreateFolder = true">新增文件夹</div>
          <div class="btn-list btn-green" @click="getBack">返回文件夹列表</div>
        </div>
      </template>
    </c-operate>

    <!-- 个人网盘 -->
    <c-table :data="folderAndFilesList">
      <!-- <el-table-column align="center">
        <template #default="{ row }">
          {{ row.id }}
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="name" label="文件夹名称"> </el-table-column>
      <el-table-column align="center" prop="remark" label="文件夹备注"> </el-table-column>
      <el-table-column align="center" prop="data" label="网盘性质">
        <template #default="{ row }">
          <span>{{ ['', '文件夹', '文件'][row.type] }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="createtime" label="创建时间" width="180"> </el-table-column>
      <el-table-column align="center" prop="date" label="操作" width="350">
        <template #default="{ row }">
          <div class="table-operate">
            <template v-if="row.type == 1">
              <p class="operate-list edit" @click="editFolder(row)">编辑</p>
              <p class="operate-list viewFolder" @click="viewFolder(row)">查看</p>
              <p class="operate-list viewUploadFile" @click="uploadFile(row)">上传文件</p>
            </template>
            <!-- <p class="operate-list follow" :class="{ active: row.isListChecked }">下载</p> -->
            <p class="operate-list deleteFileOrFolder" @click="deleteFileOrFolder(row)">删除</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center"> </el-table-column>
    </c-table>
    <c-dialog v-model="showCreateFolder" title="新建文件夹" @confirm="createFolder" :mask="true">
      <div class="create-file">
        <div class="list" style="align-items: center">
          <p class="list-name">文件夹名称</p>
          <el-input class="list-txt" v-model="createFolderParam.name" placeholder="请输入内容"></el-input>
        </div>
        <div class="list">
          <p class="list-name">文件夹备注</p>
          <el-input class="list-txt" type="textarea" v-model="createFolderParam.remark" placeholder="请输入内容"></el-input>
        </div>
      </div>
    </c-dialog>
    <!-- 编辑文件夹 -->
    <c-dialog v-model="redactFolder" title="编辑文件夹" :mask="true" @confirm="confirmEditFolder">
      <div class="create-file">
        <div class="list" style="align-items: center">
          <p class="list-name">文件夹名称</p>
          <el-input class="list-txt" v-model="editFolderParam.name" placeholder="请输入内容"></el-input>
        </div>
        <div class="list">
          <p class="list-name">文件夹备注</p>
          <el-input class="list-txt" type="textarea" v-model="editFolderParam.remark" placeholder="请输入内容"></el-input>
        </div>
      </div>
    </c-dialog>
    <!-- 上传文件 -->
    <c-dialog v-model="showUploadFile" title="上传文件" :mask="true" :cancel="false" :save="false">
      <div class="uploadFile">
        <el-row class="row" :gutter="20">
          <el-col :span="6" class="name">文件</el-col>
          <el-col :span="18">
            <c-upload @on-success="changeFile" @on-remove="changeFile" :limit="1" type="kanbox"></c-upload>
          </el-col>
        </el-row>
        <div class="btn-case">
          <div class="btn-close" @click="showUploadFile = false">取消</div>
          <div class="btn-save" @click="confirmUploadFile">确定</div>
        </div>
      </div>
    </c-dialog>
    <!-- 删除文件夹 -->
    <c-dialog v-model="showDelete" :title="deleteType == 1 ? '删除文件夹' : '删除文件'" :mask="true" @confirm="confirmDelete">
      <div class="finishCase">您确定要删除当前{{ ['', '文件夹', '文件'][deleteType] }}吗？</div>
    </c-dialog>

    <!-- 分页 -->
    <div class="page">
      <c-pagination @change="changePage" :pageSize="getFolderAndFileListParam.list" :total="contactsTotal"></c-pagination>
    </div>
  </div>
</template>

<script>
import CDialog from '@/components/c-dialog'
import * as systemApi from '@/api/system.js'
import _m from 'moment'
export default {
  name: 'System',
  components: {
    CDialog
  },
  data() {
    return {
      moment: '',
      // 网盘性质
      cloudTypeList: [
        {
          id: 0,
          name: '个人网盘',
          isChecked: true
        },
        {
          id: 1,
          name: '前台网盘',
          isChecked: false
        },
        {
          id: 2,
          name: '公共网盘',
          isChecked: false
        }
      ],
      getFolderAndFileListParam: {
        name: '',
        status: 0,
        pid: 0,
        order: 1,
        page: 1,
        list: 7
      },
      createFolderParam: {
        status: 0,
        name: '',
        remark: '',
        pid: ''
      },
      editFolderParam: {
        status: '',
        id: '',
        pid: '',
        name: '',
        remark: ''
      },
      folderAndFilesList: [],
      viewFolderPath: [''],
      showUploadFile: false,
      uploadFilesList: [],
      uploadFileUrl: '',
      uploadFileId: '',
      // ---------------------------------------------

      showNewClient: false,
      deceptionDeskFile: true, // 网盘文件按钮
      showCreateFolder: false, // 新增文件
      redactFolder: false, // 编辑文件夹
      showDelete: false, //  删除文件
      deleteType: null, // 1: 文件夹 2: 文件
      deleteId: '',
      // showListedFiles: false, // 文件夹里的文件
      isBtn: true, // 是否显示按钮
      contactsTotal: 0
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.moment = _m
      this.getFolderAndFile()
    },
    /**
     * @description: 获取文件夹于文件列表
     */
    getFolderAndFile(v) {
      this.folderAndFilesList = []

      this.getFolderAndFileListParam.pid = this.viewFolderPath[this.viewFolderPath.length - 1]
      systemApi.getFolderAndFile(this.getFolderAndFileListParam).then(res => {
        this.folderAndFilesList = res.data.data
        this.contactsTotal = res.data.total
      })
    },
    /**
     * 文件名称搜索
     */
    searchFileName(e) {
      this.getFolderAndFileListParam.name = e.keyWord
      if (e.date) {
        this.getFolderAndFileListParam.starday = e.date[0]
        this.getFolderAndFileListParam.endday = e.date[1]
      } else {
        this.getFolderAndFileListParam.starday = ''
        this.getFolderAndFileListParam.endday = ''
      }
      this.getFolderAndFile()
    },
    /**
     * @description: 新增文件夹
     */
    createFolder() {
      this.createFolderParam.pid = this.viewFolderPath[this.viewFolderPath.length - 1]
      systemApi.createFileAndFolder(this.createFolderParam).then(res => {
        this.showCreateFolder = false
        this.createFolderParam.name = ''
        this.createFolderParam.remark = ''
        this.getFolderAndFile()
        this._showMsg('新建成功', 'success')
      })
    },
    /**
     * @description: 改变网盘性质
     * @return {*}
     */
    changeNavigation(e) {
      this.getFolderAndFileListParam.status = e.id
      this.getFolderAndFile()
    },
    // 跟进消息
    follow(row) {
      this.showFollowUpInfoDetails = true
    },
    /**
     * 跳转页数
     */
    changePage(i) {
      this.getFolderAndFileListParam.page = i
      this.getFolderAndFile()
    },
    /**
     * @description: 查看文件夹里的文件
     * @param {*} e: 文件夹的数据
     */
    viewFolder(e) {
      this.viewFolderPath.push(e.id)
      this.getFolderAndFileListParam.pid = e.id
      this.getFolderAndFile()
    },
    /**
     * @description: 返回文件夹列表
     */
    getBack() {
      if (this.viewFolderPath.length > 1) {
        const v = this.viewFolderPath.pop()
        console.log('[ 🎯 this.viewFolderPath ]', this.viewFolderPath)
        this.getFolderAndFile()
      } else {
        this._showMsg('已没有上级目录')
      }
    },
    /**
     * @description: 上传文件
     * @param {*} e: 上传的参数
     */
    uploadFile(e) {
      this.showUploadFile = true
      this.uploadFileId = e.id
      this.uploadFileUrl = e.url
    },
    /**
     * @description: 修改上传的文件
     * @param {*} v: 修改后的数据
     */
    changeFile(v) {
      this.uploadFilesList = v
    },
    /**
     * @description: 确定上传文件
     */
    confirmUploadFile() {
      this.showUploadFile = false
      const param = new FormData()
      param.append('file', this.uploadFilesList[0].file)
      param.append('kanbox_status', this.getFolderAndFileListParam.status)
      param.append('kanbox_pid', this.uploadFileId)
      param.append('filepath', this.uploadFileUrl)
      systemApi.uploadFile(param).then(res => {
        this._showMsg('上传成功', 'success')
        this.getFolderAndFile()
      })
    },
    // 获取前台文件
    // getDeceptionDeskFile() {
    //   this.deceptionDeskFile = false
    //   this.showListedFiles = true
    //   this.isBorder = false
    //   this.isBtn = false
    // },

    // 查看前台文件夹里的文件
    // checkFile(row) {
    //   this.isBorder = true
    //   this.isBtn = true
    //   this.showListedFiles = false
    // },
    // 下载文件
    downloadFile() {},
    /**
     * @description:
     */
    /**
     * @description: 编辑文件夹
     * @param {*} v: 要编辑的数据
     * @return {*}
     */
    editFolder(v) {
      for (const key in this.editFolderParam) {
        this.editFolderParam[key] = v[key]
      }
      this.redactFolder = true
    },
    /**
     * @description: 确定编辑文件
     */
    confirmEditFolder() {
      systemApi.editFolder(this.editFolderParam).then(res => {
        this.redactFolder = false
        this.getFolderAndFile()
        this._showMsg('编辑成功', 'success')
      })
    },
    /**
     * @description: 删除文件或文件夹
     * @param {*} row: 要删除的数据
     * @return {*}
     */
    deleteFileOrFolder(row) {
      this.deleteType = row.fileType
      this.deleteId = row.id
      this.showDelete = true
    },
    /**
     * @description: 确定删除文件夹
     */
    confirmDelete() {
      systemApi.deleteFileOrFolder({ id: this.deleteId }).then(res => {
        this.getFolderAndFile()
        this._showMsg('删除成功', 'success')
        this.showDelete = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.memo-page {
  background-color: #fff;
}
.btn-group {
  .courtDate {
    background: linear-gradient(230deg, #fc9940, #f37902);
  }
  .export {
    background: linear-gradient(109deg, #2fca70, #05c154);
  }
}

.table-operate {
  .edit {
    color: #179d92;
    // margin-right: 8px;
  }

  .viewFolder {
    color: #f3861c;
  }
  .viewUploadFile {
    color: #2466fa;
  }
  .deleteFileOrFolder {
    color: #fc394a;
  }
  .check-file {
    color: #333333;
  }
  .active {
    color: #2466fa;
  }
  .check-file-active {
    color: #f3861c;
  }
}
.uploadFile {
  width: 250px;
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .name {
    font-size: 16px;
    text-align: right;
  }

  .memoData {
    display: grid;
    align-items: flex-start;
    margin-top: 1.51rem;
    margin-bottom: 2.71rem;
  }
  .btn-case {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 107px;
      height: 37px;
      background: #e5ebeb;
      border-radius: 15px;
      font-size: 16px;
    }
    .btn-save {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 107px;
      height: 37px;
      background: linear-gradient(215deg, #1db8ab, #179d92);
      border-radius: 15px;
      font-size: 16px;
      color: #ffffff;
    }
  }
}
.finishCase {
  padding: 2.66rem 4.51rem;
  text-align: center;
  font-size: 0.94rem;
  color: #333333;
  font-weight: 400;
  letter-spacing: 0.1rem;
}
.btn-green {
  background: linear-gradient(109deg, #2fca70, #05c154);
}

.create-file {
  width: 500px;
  .list {
    margin-bottom: 20px;
    display: flex;

    .list-name {
      text-align: right;
      padding-right: 20px;
      width: 130px;
      flex-shrink: 0;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>
